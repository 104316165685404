import { useEffect } from 'react'

const useDisableBodyScroll = (isModalOpen?: boolean, isDrawerOpen?: boolean) => {
  useEffect(() => {
    // Disable scrolling when the modal or drawer is open
    if (isModalOpen || isDrawerOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      // Re-enable scrolling when the modal or drawer is closed
      document.body.style.overflow = 'auto'
    }

    // Clean up
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isModalOpen, isDrawerOpen])
}

export default useDisableBodyScroll
