import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { string, ref, object } from 'yup'
import {
  AtButton,
  AtButtonVariant,
  AtCheckbox,
  AtLink,
  AtLinkVariant,
  getRichTextNode,
  MlFormField,
  MlHeading,
  TextAlignment,
  CheckboxVariant,
  AtButtonState,
} from '@curran-catalog/curran-atomic-library'
import { Formik } from 'formik'
import { environment } from '@config/environment'
import { RegisterValidationErrors, useAuth } from '../../hooks/use-auth'

export type RegisterValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  passwordConfirmation: string
  countryCode: string
}

const recaptchaSiteKey = environment.recaptcha.siteKey

const initialValues: RegisterValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  countryCode: 'US',
  passwordConfirmation: '',
}

const validationSchema = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  email: string().email('Email must be a valid email').required('Required'),
  country_code: string(),
  phone: string().required('Required'),
  password: string().required('Required').min(8, 'Password must be at least 8 characters'),
  passwordConfirmation: string()
    .required('Required')
    .oneOf([ref('password')], 'Passwords do not match'),
})

export const TmRegister = () => {
  const { register } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/',
  })

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const [newsletterSignup, setNewsletterSignup] = useState(false)
  const [recaptcha, setRecaptcha] = useState<string | null>(null)
  const [countryCode, setCountryCode] = useState('US')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [backendErrors, setBackendErrors] = useState<RegisterValidationErrors>({})

  const submitHandler = async (values: RegisterValues) => {
    setIsSubmitting(true)

    register({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      password: values.password,
      country_code: countryCode,
      password_confirmation: values.passwordConfirmation,
      newsletter_signup: newsletterSignup,
      recaptcha,
      setErrors: setBackendErrors,
    }).finally(() => setIsSubmitting(false))
  }

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptcha(value)
  }

  const handleCountryChange = (countryCode: string) => {
    setCountryCode(countryCode)
  }

  return (
    <div className="mx-auto md:max-w-md">
      <div className="mt-0 md:py-5">
        <MlHeading title={getRichTextNode('Create an account', 'heading-2')} textAlignment={TextAlignment.LEFT} />
      </div>
      <div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitHandler}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {
            const isInvalid = Object.keys(errors).length > 0
            const isFormFilled = Object.values(values).every((value) => value !== '')
            const isFormValid = !isInvalid && isFormFilled

            return (
              <form onSubmit={handleSubmit}>
                <div className="mx-auto flex flex-col gap-y-6 mt-3">
                  <MlFormField
                    label="First name"
                    type="text"
                    error={(touched.firstName && Boolean(errors.firstName || backendErrors.firstName)) || undefined}
                    value={values.firstName}
                    name="firstName"
                    errorMessage={(touched.firstName && (errors.firstName || backendErrors.firstName)) || undefined}
                    required
                    autoFocus
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="given-name"
                  />
                  <MlFormField
                    label="Last name"
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    error={touched.lastName && Boolean(errors.lastName || backendErrors.lastName)}
                    errorMessage={(touched.lastName && (errors.lastName || backendErrors.lastName)) || undefined}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="family-name"
                  />
                  <MlFormField
                    label="Email"
                    type="email"
                    name="email"
                    value={values.email}
                    error={touched.email && Boolean(errors.email || backendErrors.email)}
                    errorMessage={(touched.email && (errors.email || backendErrors.email)) || undefined}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="email"
                  />
                  <MlFormField
                    label="Phone"
                    type="tel"
                    name="phone"
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone || backendErrors.phone)}
                    errorMessage={(touched.phone && (errors.phone || backendErrors.phone)) || undefined}
                    required
                    onCountryChange={handleCountryChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="tel"
                  />
                  <MlFormField
                    label="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    error={touched.password && Boolean(errors.password || backendErrors.password)}
                    errorMessage={(touched.password && (errors.password || backendErrors.password)) || undefined}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="new-password"
                  />
                  <MlFormField
                    label="Confirm password"
                    type="password"
                    name="passwordConfirmation"
                    required
                    error={
                      touched.passwordConfirmation &&
                      Object.prototype.hasOwnProperty.call(errors || backendErrors, 'passwordConfirmation')
                    }
                    value={values.passwordConfirmation}
                    errorMessage={(touched.passwordConfirmation && errors.passwordConfirmation) || undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="new-password"
                  />
                  <AtCheckbox
                    name="test"
                    type="checkbox"
                    checked={newsletterSignup}
                    checkboxVariant={CheckboxVariant.FILLED}
                    label="Join our mailing list to receive exclusive sale alerts and updates."
                    onChange={(event) => setNewsletterSignup(event.target.checked)}
                  />
                  <div className="flex flex-col gap-1">
                    {!!recaptchaSiteKey && (
                      <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} onChange={handleRecaptchaChange} />
                    )}
                    {!!recaptchaSiteKey && backendErrors.recaptcha && (
                      <span className="tw-text-error tw-text-sm tw-leading-4">{backendErrors.recaptcha}</span>
                    )}
                  </div>
                  <AtButton
                    type="submit"
                    label="Create account"
                    variant={AtButtonVariant.SECONDARY}
                    icon={isSubmitting ? { type: 'spinner' } : undefined}
                    state={!recaptcha || isSubmitting ? AtButtonState.DISABLED : AtButtonState.ENABLED}
                    className="w-full"
                    disabled={!recaptcha || isSubmitting || !isFormValid}
                  >
                    <span className="tw-text-error tw-text-sm tw-leading-4">{backendErrors.recaptcha}</span>
                  </AtButton>
                  <small>
                    By clicking “Create account” above, you agree to our{' '}
                    <AtLink href="/customer-service/privacy-policy" variant={AtLinkVariant.DEFAULT}>
                      privacy policy
                    </AtLink>
                  </small>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
