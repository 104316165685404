import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AtButton,
  AtButtonState,
  AtButtonVariant,
  AtInfoItem,
  AtInfoItemVariant,
  MlBreadcrumb,
  MlSampleCardProps,
  OrDrawer,
  OrModal,
  useSessionStorage,
} from '@curran-catalog/curran-atomic-library'
import { FlooringWeaveActionTypes } from 'context'
import { CollapseSection } from '@components/flooring-product-detail'
import { ObjFlooringWeave, WeaveParametersResponse, WeaveStartingAtPriceResponse } from '@services/prices'
import { useCart, useFlooringWeave, useMediaQuery, useModal } from 'hooks'
import { ContainerWrapper } from '@utils/container-wrapper'
import { normalizeInfoItem } from '@utils/normalize'
import { ContentfulObjFlooringBorderColor, ContentfulObjFlooringWeaveColor, FlooringSectionsIDs } from 'types'
import { useRouter } from 'next/router'
import { getErrorModalProps } from '@config/modal-error'
import { HandleClickProps, normalizeSampleCard, TypeSample } from '../../components/rug-builder/order-samples'
import { SampleCartDTO } from '@services/cart'
import useDisableBodyScroll from '@hooks/use-disable-body-scroll'
import { SynSisalProductDescription } from '@components/synsisal-product-detail'
import { ImageGallery } from '@components/flooring-product-detail/image-gallery'
import { handleUnknownError } from '@utils/errors'
import { sendProductMeasureAnalyticsEvent } from '@utils/analytics/events/measuring-product/send-event'
import { CartEventTypes, ProductMeasureEventTypes } from '../../types/analytics/analytics-data'
import { sendCartAnalyticsEvent } from '@utils/analytics/events/cart/send-event'

interface TmSynsisalProductDetailProps {
  product: ObjFlooringWeave & {
    parameters: WeaveParametersResponse
    startingAtPrice: WeaveStartingAtPriceResponse
  }
}

export const TmSynsisalProductDetail = ({ product }: TmSynsisalProductDetailProps) => {
  const router = useRouter()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {
    state: { weaveName, selectedColor },
    state,
    dispatch,
  } = useFlooringWeave()
  const { isModalOpen, setIsModalOpen, modalInfo, isModalErrorOpen, setIsModalErrorOpen } = useModal()
  const [isCartPageLoading, setCartPageLoading] = useState(false)
  const [previousRoute] = useSessionStorage('x-previousTitleRoute', '')
  const [_previousListRoute, setPreviousListRoute] = useSessionStorage('x-previousListTitleRoute', '')
  const [productIndexId] = useSessionStorage<string | null>('productIndexID', null)
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isWallToWall = !product.supportsRugBuilder && !product.supportsTiles && !product.supportsWallCovering
  const showProductOptions = product.supportsRugBuilder && !isWallToWall

  const isDisabled =
    !product.callForQuote &&
    ((!state.selectedColor && product.supportsTiles) ||
      (!(state.selectedColor && state.sizeWallCovering) && product.supportsWallCovering))

  const handleClickButton = () => {
    router.push('/contact-us')
  }

  useEffect(() => {
    form?.current?.reset()
  }, [])

  useEffect(() => {
    if (previousRoute) {
      setPreviousListRoute(previousRoute)
    }
  }, [previousRoute, setPreviousListRoute])

  useEffect(() => {
    dispatch({ type: FlooringWeaveActionTypes.SET_WEAVE_NAME, weaveNamePayload: product.weaveName })

    if (router.asPath.includes(FlooringSectionsIDs.SAMPLES)) {
      dispatch({ type: FlooringWeaveActionTypes.SET_TAB_ACTIVE, tabActivePayload: FlooringSectionsIDs.SAMPLES })
    }

    dispatch({ type: FlooringWeaveActionTypes.SET_WEAVE_ID, weaveIdPayload: product.CONTENTFUL_ID })
    if (product.supportsTiles && !!product.minQuantity) {
      dispatch({ type: FlooringWeaveActionTypes.UPDATE_QUANTITY, quantityPayload: Number(product.minQuantity) })
      dispatch({ type: FlooringWeaveActionTypes.SET_TOTAL_PRICE })
    }
  }, [dispatch, product, product.minQuantity, product.supportsTiles, router.asPath])

  useEffect(() => {
    if (weaveName && selectedColor) {
      sendProductMeasureAnalyticsEvent(
        {
          weaveName,
          selectedColor,
        },
        ProductMeasureEventTypes.VIEW_ITEM,
        productIndexId,
        undefined,
        previousRoute,
      )
    }
  }, [previousRoute, productIndexId, selectedColor, weaveName])

  const errorModalProps = getErrorModalProps(
    () => {
      setIsModalErrorOpen(false)
      router.push('/customer-service')
    },
    () => {
      setIsModalErrorOpen(false)
    },
  )

  const form = useRef<HTMLFormElement>(null)

  const { cart, addSampleToCart, removeItem } = useCart()

  const handleToggleSample = useCallback(
    async ({ index, id, name, type, addToCart, img, weaveId, borderId, cartId, borderName }: HandleClickProps) => {
      const currentItemQuantity = cart?.items?.find((item) => item.metadata?.contentful_id === id)?.quantity || 1
      const sampleInfo: SampleCartDTO = {
        color: name,
        type,
        quantity: currentItemQuantity,
        weaveReference: weaveId,
        ...(type === TypeSample.BORDER && { borderReference: borderId, borderName }),
        imageAlt: img.alt,
        imageSrc: img.src,
        metadata: { contentful_id: id, type: 'sample', subType: type, link: window.location.href },
      }
      if (addToCart) {
        try {
          await addSampleToCart(sampleInfo)
          sendCartAnalyticsEvent(
            sampleInfo,
            CartEventTypes.ADD_TO_CART,
            index,
            sampleInfo.quantity,
            previousRoute,
            product.name,
          )
        } catch (err) {
          setIsModalErrorOpen(true)
          handleUnknownError()(err)
        }
      } else {
        try {
          if (cartId) {
            await removeItem({ id: cartId as number })
            sendCartAnalyticsEvent(
              sampleInfo,
              CartEventTypes.REMOVE_FROM_CART,
              index,
              sampleInfo.quantity,
              previousRoute,
              product.name,
            )
          }
        } catch (err) {
          setIsModalErrorOpen(true)
          handleUnknownError()(err)
        }
      }
    },
    [addSampleToCart, cart?.items, previousRoute, product.name, removeItem, setIsModalErrorOpen],
  )

  const sampleCartItem = useCallback(
    (sampleInfo: ContentfulObjFlooringWeaveColor | ContentfulObjFlooringBorderColor) => {
      return cart?.items?.find(
        (itemCart) => itemCart.metadata && itemCart.metadata.contentful_id === sampleInfo.CONTENTFUL_ID,
      )
    },
    [cart],
  )

  const productSamples = useMemo(() => {
    let samples: MlSampleCardProps[] = []
    if (product.colorGroup && product.colorGroup.weaveColors) {
      samples = product.colorGroup.weaveColors
        .filter((color) => color.status === 'Active')
        .map((color, index) =>
          normalizeSampleCard({
            index,
            item: color,
            itemName: color.weaveColorName,
            type: TypeSample.WEAVE,
            handleClick: handleToggleSample,
            cartSample: sampleCartItem(color),
            weaveId: product.CONTENTFUL_ID,
            subheader: color.samplesSubheaderSynsisal || '',
            samplesInStock: color.samplesInStock,
          }),
        )
    }
    return [
      {
        titleContainer: product.colorSamplesTitle ? `${product.colorSamplesTitle}` : `Rug Weave Samples - FREE`,
        samplesGroup: [
          {
            samples: samples,
          },
        ],
      },
    ]
  }, [product, sampleCartItem, handleToggleSample])

  useDisableBodyScroll(isModalOpen, isDrawerOpen)

  return (
    <div className="flex flex-col gap-8">
      <ContainerWrapper className="gap-10 flex-1 flex flex-col">
        <MlBreadcrumb
          productDetailBreadcrumb={{
            categoryName: 'All weaves',
            categorySlug: '#',
            name: product.weaveName,
            nameSlug: product.weaveNameSlug,
          }}
        />
        {product.hasSamples && (
          <OrDrawer
            isOpen={isDrawerOpen}
            title="Order Samples"
            description={product.samplesDrawerDescription}
            sampleGroups={productSamples}
            actionButton={{
              label: 'View Cart',
              onClick: () => {
                setCartPageLoading(true)
                router.push('/cart')
              },
              state: isCartPageLoading ? AtButtonState.LOADING : AtButtonState.ENABLED,
              icon: isCartPageLoading ? { type: 'spinner' } : undefined,
              disabled: isCartPageLoading,
            }}
            handleCloseDrawer={() => {
              setIsDrawerOpen(false)
            }}
          />
        )}

        {/* Description Mobile/Tablet */}
        {isMobile && (
          <SynSisalProductDescription
            supportRugBuilder={product.supportsRugBuilder || product.rugBuilderWallToWallCheck}
            isTile={product.supportsTiles}
            isWallToWall={false}
            product={product}
            className={`${showProductOptions ? 'block md:hidden' : 'xl:hidden'} flex flex-col gap-4`}
            showColorPicker={true}
          />
        )}

        <div className="relative flex flex-col md:flex-row md:justify-between gap-y-8 gap-x-8">
          <div className="tw-gallery-size tw-gallery-container !h-auto">
            <div className="md:sticky md:top-24 lg:top-40">
              <ImageGallery product={product} />
            </div>
          </div>

          <form
            className="text-secondary flex flex-col gap-4 md:w-5/12 xl:basis-5/12 w-full"
            onSubmit={(e) => e.preventDefault()}
          >
            {/* Description Desktop */}
            {!isMobile && (
              <SynSisalProductDescription
                supportRugBuilder={product.supportsRugBuilder || product.rugBuilderWallToWallCheck}
                isTile={product.supportsTiles}
                product={product}
                className={`${showProductOptions ? 'hidden md:block' : 'hidden'} xl:flex xl:flex-col xl:gap-4`}
                showColorPicker={true}
              />
            )}

            {/* Order Button */}
            {product.hasSamples && (
              <AtButton
                type="button"
                disabled={isDisabled}
                label="Order Samples"
                variant={AtButtonVariant.SECONDARY}
                state={isDisabled ? AtButtonState.DISABLED : AtButtonState.ENABLED}
                onClick={() => {
                  setIsDrawerOpen(true)
                }}
              />
            )}

            <AtButton
              type="button"
              label="Contact for Quote"
              variant={AtButtonVariant.TERTIARY}
              onClick={() => handleClickButton()}
            />

            {/* Shopping info text*/}
            {Array.isArray(product.synSisalAdditionalInformation) &&
              product.synSisalAdditionalInformation.length > 0 &&
              product.synSisalAdditionalInformation.map((infoItem, index) => (
                <AtInfoItem
                  key={`add-info-${infoItem.CONTENTFUL_ID}-${index}`}
                  {...normalizeInfoItem(infoItem)}
                  variant={AtInfoItemVariant.SHIPPING}
                />
              ))}
          </form>
        </div>
      </ContainerWrapper>

      <CollapseSection product={product} />

      {/*Modal order*/}
      <OrModal
        productContent={modalInfo?.productContent}
        isOpen={isModalOpen}
        icon="circle-check"
        title="Added to Shopping Cart"
        leftButton={{
          label: 'Continue Shopping',
          onClick: () => {
            setIsModalOpen(false)
          },
          variant: AtButtonVariant.TERTIARY,
        }}
        rightButton={{
          label: 'View Cart',
          onClick: () => {
            setCartPageLoading(true)
            router.push('/cart')
          },
          state: isCartPageLoading ? AtButtonState.LOADING : AtButtonState.ENABLED,
          icon: isCartPageLoading ? { type: 'spinner' } : undefined,
          disabled: isCartPageLoading,
        }}
        handleCloseModal={() => setIsModalOpen(false)}
      />

      <OrModal
        isOpen={isModalErrorOpen}
        title="Error"
        description={errorModalProps.description}
        leftButton={errorModalProps.leftButton}
        rightButton={errorModalProps.rightButton}
        handleCloseModal={() => setIsModalErrorOpen(false)}
      />
    </div>
  )
}
