import { useEffect, useMemo, useState } from 'react'
import {
  getRichTextNode,
  MlBreadcrumb,
  MlGallery,
  MlRichTextProps,
  OrCollapseProps,
  useSessionStorage,
} from '@curran-catalog/curran-atomic-library'

import { ProductDetailCollapses } from '@components/product-detail-collapses'
import { useFurnitureProduct } from '@hooks/use-furniture-product'
import { ContainerWrapper } from '@utils/container-wrapper'
import { normalizeAsset, normalizeObjFurnitureProduct } from '@utils/normalize'
import { getSpecificationOptions } from './utils'
import { FurnitureProductActionTypes } from '@context/furniture-product'
import { environment } from '@config/environment'
import { getSpecsWeaveRichText } from '@utils/get-custom-richtext'
import { ContentfulObjFurnitureProduct, ErrorFurniturePrices, FurniturePrices } from 'types'
import dynamic from 'next/dynamic'

import { ProductDescription } from '@components/furniture-product-detail'
import { useRouter } from 'next/router'
import { sendProductMeasureAnalyticsEvent } from '@utils/analytics/events/measuring-product/send-event'
import { ProductMeasureEventTypes } from '../../types/analytics/analytics-data'

import { renderContentfulMlVideo } from '@utils/render'

interface TmFurnitureProductDetailProps {
  product: ContentfulObjFurnitureProduct
  prices: FurniturePrices | ErrorFurniturePrices
}

export const TmFurnitureProductDetail = ({ product, prices }: TmFurnitureProductDetailProps) => {
  const {
    category,
    categorySlug,
    category2,
    category2Slug,
    collection,
    images,
    productNameSlug,
    productName,
    specifications,
    specificationsText,
    vendor,
    vendorSkus,
    video,
  } = product
  const { state, dispatch } = useFurnitureProduct()
  const router = useRouter()
  const { basePrice, cushionMinPrice, startingPrice } = state

  const [productIndexId] = useSessionStorage<string | null>('productIndexID', null)
  const [previousRoute] = useSessionStorage('x-previousTitleRoute', product.productName)

  const [normalizedProduct, setNormalizedProduct] = useState<ContentfulObjFurnitureProduct>()

  const OrCollapseContainer = dynamic(() =>
    import('@curran-catalog/curran-atomic-library').then((mod) => mod.OrCollapseContainer),
  )

  const renderedVideoBlock = useMemo(() => {
    if (!video) {
      return
    }
    return renderContentfulMlVideo({ block: video })
  }, [video])

  useEffect(() => {
    const handleRouteChange = (queryID: string) => {
      if (!queryID) return

      dispatch({ type: FurnitureProductActionTypes.SET_CURRENT_QUERY_ID, payload: queryID })
    }
    const algoliaQueryIdHistory = localStorage.getItem('AlgoliaQueryIdHistory')
    const algoliaQueryIdHistoryObj = algoliaQueryIdHistory ? JSON.parse(algoliaQueryIdHistory) : null
    // Initial setup
    if (algoliaQueryIdHistoryObj && algoliaQueryIdHistoryObj[product.CONTENTFUL_ID]) {
      handleRouteChange(algoliaQueryIdHistoryObj[product.CONTENTFUL_ID])
    }

    // Listen for route changes

    const routeChangeComplete = () =>
      handleRouteChange(
        algoliaQueryIdHistoryObj && algoliaQueryIdHistoryObj[product.CONTENTFUL_ID]
          ? algoliaQueryIdHistoryObj[product.CONTENTFUL_ID]
          : '',
      )
    router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      // Clean up the event listener to prevent memory leaks
      router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [dispatch, product.CONTENTFUL_ID, router.events, router.asPath])

  useEffect(() => {
    normalizeObjFurnitureProduct(product)
      .then((res) => {
        setNormalizedProduct(res)
      })
      .finally(() => dispatch({ type: FurnitureProductActionTypes.SET_LOADING_PRICES, isLoadingPrices: false }))
  }, [dispatch, product])

  // Set the starting price
  useEffect(() => {
    if (Array.isArray((prices as FurniturePrices).products) && (prices as FurniturePrices).products.length > 0) {
      const sortedProductPrices = [...(prices as FurniturePrices).products].sort(
        (a, b) => (a.price ?? 0) - (b.price ?? 0),
      )

      dispatch({
        type: FurnitureProductActionTypes.SET_STARTING_PRICE,
        startingPrice: sortedProductPrices[0].price,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices])

  const collapseItems: OrCollapseProps[] = []

  collection?.description &&
    collapseItems.push({
      id: 0,
      isOpen: true,
      title: 'Collection Overview',
      content: [{ text: collection?.description }] as MlRichTextProps[],
    })

  const specificationContent = [...getSpecificationOptions(specifications), { text: specificationsText }]

  vendorSkus &&
    specificationContent.unshift({
      text: getSpecsWeaveRichText({ itemTitle: 'SKU', itemValue: vendorSkus.join(', ') }),
    })

  collapseItems.push({
    id: 1,
    isOpen: true,
    title: 'Specifications',
    content: specificationContent as MlRichTextProps[],
  })

  collection?.useAndCareGuidelines &&
    collapseItems.push({
      id: 2,
      isOpen: true,
      title: 'Use and Care',
      content: [{ text: collection?.useAndCareGuidelines }] as MlRichTextProps[],
    })

  collection?.shippingInfo?.definedTerms &&
    collapseItems.push({
      id: 3,
      isOpen: true,
      title: 'Shipping',
      content: [
        { text: getRichTextNode(`Method: ${collection?.shippingInfo?.method ?? '-'}`, 'paragraph') },
        { text: getRichTextNode(`Lead Time: ${collection?.shippingInfo?.leadTime ?? '-'}`, 'paragraph') },
        { text: collection?.shippingInfo?.definedTerms },
      ] as MlRichTextProps[],
    })

  const productDataForGoogleMerchantCenter = JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    sku: product.sku,
    image: Array.isArray(images) && images.length > 0 && images.map((img) => normalizeAsset({ asset: img }))[0].src,
    name: productName,
    brand: {
      '@type': 'Brand',
      name: vendor?.vendorName,
    },
    offers: {
      '@type': 'Offer',
      url: environment.furnitureRootUrl + '/products/' + productNameSlug,
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
      price: basePrice + cushionMinPrice,
      priceCurrency: 'USD',
    },
  })

  useEffect(() => {
    if (normalizedProduct && prices) {
      const basePrice = (prices as FurniturePrices).products[0].price
      if (normalizedProduct?.mainCushions?.length && cushionMinPrice) {
        sendProductMeasureAnalyticsEvent(
          product,
          ProductMeasureEventTypes.VIEW_ITEM,
          productIndexId,
          basePrice + cushionMinPrice,
          previousRoute || product.productName,
        )
      }
      if (!normalizedProduct?.mainCushions?.length) {
        sendProductMeasureAnalyticsEvent(
          product,
          ProductMeasureEventTypes.VIEW_ITEM,
          productIndexId,
          basePrice,
          previousRoute || product.productName,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalizedProduct, startingPrice, cushionMinPrice, prices])

  return (
    <>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: productDataForGoogleMerchantCenter }} />

      <div className="flex flex-col gap-8">
        <ContainerWrapper className="gap-10 flex-1 flex flex-col">
          <MlBreadcrumb
            productDetailBreadcrumb={{
              categoryName: category,
              categorySlug: `${category2Slug ? category2Slug + '/' : ''}${categorySlug}`,
              collectionName: collection ? collection.collectionName : undefined,
              collectionSlug: collection ? collection?.collectionNameSlug : undefined,
              category2Name: category2,
              category2Slug: category2Slug,
              name: productName,
              nameSlug: productNameSlug,
            }}
          />

          {/* Header Mobile/Tablet */}
          <h1 className="sr-only">{product.name}</h1>
          <ProductDescription
            product={product}
            cushionMinPrice={cushionMinPrice}
            startingPrice={startingPrice}
            className="xl:sr-only not-sr-only min-h-[96px]"
          />

          <div className="relative flex flex-col md:flex-row md:justify-between gap-y-8 gap-x-8">
            <div className="!h-auto">
              <div className="tw-gallery-size tw-gallery-container md:sticky md:top-24 lg:top-40 xl:w-[732px]">
                <MlGallery
                  images={
                    Array.isArray(images) && images.length > 0
                      ? images.map((image, index) => normalizeAsset({ asset: { ...image, priority: index <= 1 } }))
                      : [{ src: '/images/image-coming-soon.jpg', alt: '' }]
                  }
                />
              </div>
            </div>
            <ProductDetailCollapses product={!normalizedProduct ? product : normalizedProduct} productPrices={prices} />
          </div>
        </ContainerWrapper>
        {renderedVideoBlock}
        <OrCollapseContainer className="pb-8" collapseItems={collapseItems} />
      </div>
    </>
  )
}
