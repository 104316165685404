interface AuthSessionStatusProps {
  status: string | null
  className: string
}

const AuthSessionStatus = ({ status, className, ...props }: AuthSessionStatusProps) => (
  <>
    {status && (
      <div className={`${className} font-normal text-sm text-success`} {...props}>
        {status}
      </div>
    )}
  </>
)

export default AuthSessionStatus
