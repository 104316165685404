import { MlBreadcrumb, OrSidebarMenu } from '@curran-catalog/curran-atomic-library'
import { useRouter } from 'next/router'

import { ContentfulBlock, ContentfulOrSidebarMenu } from 'types'
import { getRenderedBlocks } from '@utils/render'
import { SidebarContext, SidebarMenuActionTypes } from 'context'
import { useContext, useEffect } from 'react'
import { normalizeSidebar } from '@utils/normalize/sidebar'
import { ContainerWrapper } from 'utils/'

export interface TmSidebarProps {
  sidebarMenu: ContentfulOrSidebarMenu
  blocks: ContentfulBlock[]
}

export const TmSidebar = ({ sidebarMenu, blocks }: TmSidebarProps) => {
  const { state: sidebarState, dispatch: sidebarDispatch } = useContext(SidebarContext)
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', () =>
      sidebarDispatch({ type: SidebarMenuActionTypes.TOGGLE_MODAL, payload: false }),
    )
  }, [router.events, sidebarDispatch])

  const handleCloseMenu = () => {
    sidebarDispatch({ type: SidebarMenuActionTypes.TOGGLE_MODAL, payload: false })
  }

  return (
    <ContainerWrapper className="gap-4 md:gap-6 flex-1 flex flex-col p-0">
      <div className="px-2 py-3">
        <MlBreadcrumb asPath={router.asPath} />
      </div>

      <div className="tw-flex">
        <OrSidebarMenu
          {...normalizeSidebar(sidebarMenu, router.asPath)}
          isOpen={sidebarState.isOpen}
          handleCloseMenu={handleCloseMenu}
        />
        <main className="custom-width-container">{getRenderedBlocks(blocks, false)}</main>
      </div>
    </ContainerWrapper>
  )
}
