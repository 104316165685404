import { ReactNode, useCallback } from 'react'
import { useRouter } from 'next/router'
import {
  containerSectionHorizontalPaddingClasses,
  MlBeautyBanner,
  MlBreadcrumb,
  MlContentStrip,
  MlHeading,
  OrCollapseContainer,
  toKebabCase,
} from '@curran-catalog/curran-atomic-library'

import { TmCatalogProps } from 'pages/catalog/[[...slug]]'
import { normalizeContentStrip, normalizeCollapseContainer, normalizeLink } from '@utils/normalize'
import { getRenderedBlocks } from '@utils/index'

export const TmCatalog = ({
  children,
  beautyBanner,
  heading,
  productBanner,
  collapseContainer,
  blocks,
  extraBlocks,
}: Pick<
  TmCatalogProps,
  'beautyBanner' | 'productBanner' | 'collapseContainer' | 'heading' | 'blocks' | 'extraBlocks'
> & {
  children: ReactNode
}) => {
  const router = useRouter()

  const renderBanner = useCallback(() => {
    if (productBanner) {
      return <MlContentStrip {...normalizeContentStrip(productBanner, true)} id={toKebabCase(productBanner.name)} />
    } else if (beautyBanner && Array.isArray(beautyBanner.imageLinks) && beautyBanner.imageLinks.length > 0) {
      return (
        <>
          {heading && <MlHeading {...heading} id={toKebabCase(heading.name)} />}
          <MlBeautyBanner imageLinks={beautyBanner.imageLinks.map((imageLink) => normalizeLink(imageLink, true))} />
        </>
      )
    } else return null
  }, [beautyBanner, productBanner, heading])

  return (
    <main className={`flex-1 flex flex-col tw-container mx-auto ${containerSectionHorizontalPaddingClasses}`}>
      <div className="py-3">
        <MlBreadcrumb asPath={router.asPath.replace('#top', '')} />
      </div>
      {renderBanner() && <div className="flex flex-col gap-6 py-4 pb-8">{renderBanner()}</div>}
      <div className="flex flex-col">{children}</div>
      {blocks && blocks.length > 0 && <div className="py-20">{getRenderedBlocks(blocks)}</div>}
      {collapseContainer && <OrCollapseContainer {...normalizeCollapseContainer(collapseContainer)} className="mt-5" />}
      {extraBlocks && getRenderedBlocks(extraBlocks)}
    </main>
  )
}
