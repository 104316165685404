import AuthSessionStatus from '@components/AuthSessionStatus'
import {
  AtButton,
  AtButtonState,
  AtButtonVariant,
  AtCheckbox,
  AtLink,
  AtLinkVariant,
  CheckboxVariant,
  getRichTextNode,
  MlFormField,
  MlHeading,
  TextAlignment,
} from '@curran-catalog/curran-atomic-library'
import { useRouter } from 'next/router'
import { FormEventHandler, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { LoginValidationErrors, useAuth } from '../../hooks/use-auth'
import { renderLinkWrapper } from '@utils/link-wrapper'

export const TmLogin = () => {
  const router = useRouter()

  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/',
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const [errors, setErrors] = useState<LoginValidationErrors>({})
  const [status, setStatus] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (router.query.reset && router.query.reset.length > 0 && errors === null) {
      setStatus(atob(String(router.query.reset)))
    } else {
      setStatus(null)
    }
  }, [router.query.reset, errors])

  useEffect(() => {
    setErrors({})
  }, [email, password])

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    setIsSubmitting(true)

    login({ email, password, remember, setErrors, setStatus })
      .catch(() => {
        toast.error('An error occurred while logging in. Please try again.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }
  return (
    <div className="md:max-w-md mx-auto">
      <div className="mt-0 md:py-5">
        <MlHeading title={getRichTextNode('Sign in', 'heading-2')} textAlignment={TextAlignment.LEFT} />
        Or{' '}
        <AtLink
          actionUrl={'/register' + (router.query.intended ? '?intended=' + router.query.intended.toString() : '')}
          linkWrapper={renderLinkWrapper}
          variant={AtLinkVariant.DEFAULT}
        >
          create an account
        </AtLink>
      </div>
      <div>
        {/* Session Status */}
        <AuthSessionStatus className="mb-4" status={status} />

        <form onSubmit={handleSubmit}>
          <div className="mx-auto flex flex-col gap-y-4">
            <div className="mt-3">
              <MlFormField
                label="Email"
                id="email"
                type="email"
                error={Object.prototype.hasOwnProperty.call(errors, 'email')}
                errorMessage={errors.email}
                required
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
                autoComplete="email"
              />
            </div>
            <div className="mt-2">
              <MlFormField
                label="Password"
                id="password"
                type="password"
                error={Object.prototype.hasOwnProperty.call(errors, 'password')}
                errorMessage={errors.password}
                required
                onChange={(event) => setPassword(event.target.value)}
                autoComplete="current-password"
              />
            </div>
            <div className="mt-3 flex items-center justify-between">
              <AtCheckbox
                name="remember"
                type="checkbox"
                checkboxVariant={CheckboxVariant.FILLED}
                label="Remember me"
                onChange={(event) => setRemember(event.target.checked)}
              />

              <AtLink actionUrl="/forgot-password" linkWrapper={renderLinkWrapper} variant={AtLinkVariant.DEFAULT}>
                Forgot your password?
              </AtLink>
            </div>

            <AtButton
              className="mt-3"
              state={
                Object.keys(errors).length > 0
                  ? AtButtonState.DISABLED
                  : isSubmitting
                  ? AtButtonState.LOADING
                  : AtButtonState.ENABLED
              }
              type="submit"
              label="Sign in"
              variant={AtButtonVariant.SECONDARY}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
