import { TruncateDescription } from '@components/common-product-detail'
import { ContentfulObjFlooringWeave } from 'types'
import { ColorSlider } from '@components/flooring-product-detail/color-slider'
import { useMemo } from 'react'

interface SynSisalProductDescriptionProps {
  product: ContentfulObjFlooringWeave
  supportRugBuilder?: boolean
  isTile?: boolean
  isWallToWall?: boolean
  className?: string
  showColorPicker?: boolean
}

export const SynSisalProductDescription = ({
  product,
  supportRugBuilder = false,
  isTile = false,
  isWallToWall = false,
  className,
  showColorPicker = false,
}: SynSisalProductDescriptionProps) => {
  const rollWidth = useMemo(() => {
    const erpWeave: { [key: string]: string } = {}

    if (product.erpWeaveAttributes) {
      product.erpWeaveAttributes.forEach((weaveAttribute) => {
        if (Object.keys(weaveAttribute)[0]) {
          erpWeave[Object.keys(weaveAttribute)[0]] = Object.values(weaveAttribute)[0]
        }
      })
    }

    return erpWeave.rollWidth ? erpWeave.rollWidth : null
  }, [product.erpWeaveAttributes])
  return (
    <div className={className}>
      {/* Title */}
      <div>
        <h1 className="text-3xl">{product.weaveName}</h1>
        {!isTile && (!isWallToWall || (isWallToWall && supportRugBuilder)) && (
          <div className="mt-1">{rollWidth && <span>Max. Width: {rollWidth}</span>}</div>
        )}
      </div>

      <div className="flex flex-col-reverse md:flex-col gap-4 md:gap-0 ">
        {/* Weave Color Slider */}
        {showColorPicker && product.colorGroup && (
          <div className="md:py-2">
            <ColorSlider weaveColors={product.colorGroup.weaveColors || []} />
          </div>
        )}

        {/* Description */}
        {product.synSisalDescription && <TruncateDescription description={product.synSisalDescription} />}
      </div>
    </div>
  )
}
